// /client/src/components/App.tsx

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/useAuth';
import { fetchCurrentUser, logout } from '../store/userSlice';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { api } from '../utils/api';
import NavigationBar from './NavigationBar';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import ProfileSetupPage from './ProfileSetupPage';
import QuestionnairePage from './QuestionnairePage';
import MatchesPage from './MatchesPage';
import TigerMeetPage from './TigerMeetPage';
import LogoutConfirmationModal from './LogoutConfirmationModal';

interface AlertModalProps {
  message: string | null;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({ message, onClose }) => {
  if (!message) return null;
  
  return (
    <div className="pixel-modal-overlay" onClick={onClose}>
      <div className="pixel-modal" onClick={e => e.stopPropagation()}>
        <button className="pixel-modal__close" onClick={onClose}>×</button>
        <div className="pixel-modal__content">{message}</div>
        <div className="pixel-modal__actions">
          <button className="pixel-button pixel-button--small" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const ConsoleControls: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.user);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false); // Add state for modal

  const handleStart = () => {
    navigate('/');
  };

  const handleSelect = () => {
    if (id) {
      navigate('/profile');
    }
  };

  const handleDPadUp = () => {
    if (id) {
      navigate('/questionnaire');
    }
  };

  const handleDPadRight = () => {
    if (id) {
      navigate('/app');
    }
  };

  const handleDPadDown = () => {
    if (id) {
      navigate('/meet');
    }
  };

  const handleDPadLeft = () => {
    navigate('/');
  };

  const handleExit = async () => {
    if (id) {
      try {
        await api.post('/auth/logout');
        dispatch(logout());
        setShowLogoutModal(true); // Show the modal after successful logout
      } catch (error) {
        console.error("Logout failed:", error);
      }
    }
  };

  return (
    <>
      {/* D-Pad Controls */}
      <div className="dpad-container">
        <div className="dpad">
          <div className="dpad-cross">
            <div className="dpad-button dpad-up" onClick={handleDPadUp}></div>
            <div className="dpad-button dpad-right" onClick={handleDPadRight}></div>
            <div className="dpad-button dpad-down" onClick={handleDPadDown}></div>
            <div className="dpad-button dpad-left" onClick={handleDPadLeft}></div>
            <div className="dpad-button dpad-center"></div>
          </div>
        </div>
        <div className="exit-text">EXIT</div>
        <button
          className="power-button"
          aria-label="Power button"
          onClick={handleExit}
        ></button>
      </div>

      {/* Action Buttons */}
      <div className="action-container">
        <div className="action-buttons">
        </div>
        <div className="system-buttons">
          <button className="system-button start-button" onClick={handleStart}>START</button>
          <button className="system-button select-button" onClick={handleSelect}>SELECT</button>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && <LogoutConfirmationModal onClose={() => {
        setShowLogoutModal(false);
        navigate('/'); // Navigate to the landing page when the modal is closed
      }} />}
    </>
  );
};

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id, loading } = useAppSelector((state) => state.user);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  return (
    <div className="console-outer">
      <div className="console-inner">
        <Router>
          {/* Main Screen Area */}
          <div className="screen-area">
            <div className="screen-content">
              <div className="menu-button-container">
                <NavigationBar loggedIn={!!id} />
              </div>
              <Routes>
                {id ? (
                  <>
                    <Route path="/" element={<LandingPage setAlert={setAlertMessage} />} />
                    <Route path="/app" element={<MatchesPage />} />
                    <Route path="/profile" element={<ProfileSetupPage setAlert={setAlertMessage} />} />
                    <Route path="/questionnaire" element={<QuestionnairePage setAlert={setAlertMessage} />} />
                    <Route path="/meet" element={<TigerMeetPage />} />
                    <Route path="/login" element={<Navigate to="/" replace />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<LandingPage setAlert={setAlertMessage} />} />
                    <Route path="/login" element={<LoginPage setAlert={setAlertMessage} />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </>
                )}
              </Routes>
              {loading && (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <div className="loading-text">Loading...</div>
                </div>
              )}
            </div>
            <div className="screen-scanlines"></div>
            <div className="screen-glow"></div>
          </div>

          <ConsoleControls />
        </Router>
      </div>

      {/* Alert Modal */}
      <AlertModal message={alertMessage} onClose={() => setAlertMessage(null)} />
    </div>
  );
};

export default App;