import React, { useEffect, useState, useRef } from 'react';
import { api } from '../utils/api';
import '../styles/QuestionnairePage.css';

interface Question {
  id: number;
  question: string;
  type: string;
  scale_min_label: string;
  scale_max_label: string;
}

interface QuestionnairePageProps {
  setAlert: (message: string | null) => void;
}

const OptionBox: React.FC<{
  number: number;
  checked: boolean;
  onChange: (value: string) => void;
}> = ({ number, checked, onChange }) => (
  <label className={`option-box-label ${checked ? 'checked' : ''}`}>
    <input
      type="radio"
      value={number}
      checked={checked}
      onChange={(e) => onChange(e.target.value)}
      className="option-input"
    />
    <span className="option-number">{number}</span>
  </label>
);

const QuestionnairePage: React.FC<QuestionnairePageProps> = ({ setAlert }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Record<number, string>>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.get('/questionnaire');
        const q: Question[] = res.data;

        if (!q.every(question => question.scale_min_label && question.scale_max_label)) {
          setAlert('Some questions are missing scale labels. Please verify the database.');
          return;
        }

        setQuestions(q);

        const initialAnswers: Record<number, string> = {};
        q.forEach((question: Question) => {
          initialAnswers[question.id] = '4';
        });
        setAnswers(initialAnswers);
      } catch (error) {
        setAlert('Failed to load questionnaire');
      }
    };
    fetchQuestions();
  }, [setAlert]);

  useEffect(() => {
    if (!hasInteracted) {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        setShowTooltip(true);
      }, 500);
      return () => {
        if (tooltipTimeoutRef.current) {
          clearTimeout(tooltipTimeoutRef.current);
        }
      };
    }
  }, [hasInteracted]);

  const handleInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);

      if (hideTooltipTimeoutRef.current) {
        clearTimeout(hideTooltipTimeoutRef.current);
      }

      hideTooltipTimeoutRef.current = setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isSubmitting) return;
      const num = parseInt(e.key);
      if (num >= 1 && num <= 7 && questions[currentIndex]) {
        handleChange(questions[currentIndex].id, num.toString());
        handleInteraction();
        return;
      }
      if (e.key === 'Enter' || e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrevious();
      }
      handleInteraction();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, questions.length, isSubmitting, answers, hasInteracted]);

  const handleChange = (questionId: number, value: string) => {
    handleInteraction();
    setAnswers(prev => ({ ...prev, [questionId]: value }));
  };

  const handleNext = async () => {
    handleInteraction();
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setIsSubmitting(true);
      try {
        const answersToSubmit = Object.entries(answers).map(([question_id, answer]) => ({
          question_id: parseInt(question_id, 10),
          answer
        }));
        await api.post('/questionnaire/submit', { answers: answersToSubmit });
        setAlert('Questionnaire submitted successfully!');
      } catch (error) {
        setAlert('Failed to submit questionnaire');
      }
      setIsSubmitting(false);
    }
  };

  const handlePrevious = () => {
    handleInteraction();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (questions.length === 0) {
    return (
      <div className="questionnaire-container">
        <div className="loading-text">Loading questions...</div>
      </div>
    );
  }

  const q = questions[currentIndex];
  const totalQuestions = questions.length;
  const progressFraction = (currentIndex + 1) / totalQuestions;
  const progressPercentage = Math.round(progressFraction * 100);

  const isRicePurityQuestion = (q.id === 45);
  let customLabels: string[] = [];
  if (isRicePurityQuestion) {
    // For Rice Purity Score, each of the 7 options gets its own label
    customLabels = [
      "0-29 (Least pure)",
      "30-49",
      "50-59",
      "60-69",
      "70-79",
      "80-89",
      "90-100 (Most pure)"
    ];
  }

  return (
    <div className="questionnaire-container">
      <div className="progress-bar-row">
        <div className="progress-bar-container">
          <div className="progress-bar-background">
            <div
              className="progress-bar-fill"
              style={{ width: `${progressPercentage}%` }}
            ></div>
            <span className="progress-percentage">{progressPercentage}%</span>
          </div>
        </div>
        <div className="progress-count">
          {currentIndex + 1}/{totalQuestions}
        </div>
      </div>

      <h2 className="question-text">
        {currentIndex + 1}. {q.question}
      </h2>

      <div className="scale-container">
        {/* We create a grid with two rows:
            - First row: the options (numbers)
            - Second row: the labels 
          This prevents label text from shifting the numbers. 
        */}
        <div className="options-grid">
          {/* First row: Numbers */}
          <div className="options-row">
            {[1, 2, 3, 4, 5, 6, 7].map(value => (
              <OptionBox
                key={value}
                number={value}
                checked={answers[q.id] === value.toString()}
                onChange={(val) => handleChange(q.id, val)}
              />
            ))}
          </div>

          {/* Second row: Labels */}
          <div className="option-labels-row">
            {[1,2,3,4,5,6,7].map((value, index) => {
              if (isRicePurityQuestion) {
                // For Rice Purity: a label for each
                return <div key={value} className="option-label-cell">{customLabels[index]}</div>;
              } else {
                // For normal questions, only first and last get labels
                if (value === 1) {
                  return <div key={value} className="option-label-cell">{q.scale_min_label}</div>;
                } else if (value === 7) {
                  return <div key={value} className="option-label-cell">{q.scale_max_label}</div>;
                } else {
                  return <div key={value} className="option-label-cell"></div>;
                }
              }
            })}
          </div>
        </div>
      </div>

      <div className="navigation-buttons">
        <button
          className="pixel-button"
          onClick={handlePrevious}
          disabled={currentIndex === 0 || isSubmitting}
        >
          PREVIOUS
        </button>
        <button
          className="pixel-button"
          onClick={handleNext}
          disabled={isSubmitting}
        >
          NEXT
        </button>
      </div>

      {showTooltip && (
        <div className="tooltip-container">
          <div className="tooltip-content">
            <div className="tooltip-icon">
              <img src="/icons/pixel-bulb.png" alt="Tip icon" className="tooltip-image" />
            </div>
            <div className="tooltip-text-block">
              <p className="tooltip-text">
                Press <span className="tooltip-key">1-7</span> to select answers, use <span className="tooltip-key">←</span> and <span className="tooltip-key">→</span> arrows or <span className="tooltip-key">ENTER</span> to navigate!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionnairePage;
