// /client/src/components/TigerMeetPage.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from '../hooks/useAuth';
import { api } from '../utils/api';
import { getImageUrl } from '../utils/imageUtils';
import '../styles/TigerMeetPage.css';

// Constants
const RESIDENTIAL_COLLEGES = {
  'Butler': [-74.6577, 40.3440],
  'Forbes': [-74.6602, 40.3422],
  'Mathey': [-74.6589, 40.3466],
  'NCW': [-74.6545, 40.3445],
  'Rocky': [-74.6566, 40.3482],
  'Whitman': [-74.6558, 40.3437],
  'Yeh': [-74.6534, 40.3428]
} as const;

const CLASS_YEARS = ['2025', '2026', '2027', '2028'] as const;
const AVAILABILITY_TYPES = ['Available Now', 'Available Today', 'Available This Week', 'Available Anytime'] as const;
const CONNECTION_TYPES = [
  { id: 'platonic', label: 'Platonic Connections/Friends' },
  { id: 'romantic', label: 'Romantic Connections/Dates' },
  { id: 'casual', label: 'Casual/Flings' }
] as const;

// Types
interface MeetPreferences {
  availabilityType: typeof AVAILABILITY_TYPES[number];
  classYears: number[];
  residentialColleges: string[];
  connectionTypes: string[];
  genderPreference?: string[];
  orientationPreference?: string[];
}

interface UserProfile {
  id: number;
  name: string;
  bio: string;
  class_year: number;
  photo_url: string;
  gender_identity: string;
  sexual_orientation: string;
  residential_college: string;
  availability_type: string;
  looking_for: string[];
  is_matched: boolean;
  hobbies: string;
  personality_type: string;
  favorite_show: string;
}

const TigerMeetPage: React.FC = () => {
  const user = useAppSelector(state => state.user);
  const [preferences, setPreferences] = useState<MeetPreferences>({
    availabilityType: 'Available Now',
    classYears: [],
    residentialColleges: [],
    connectionTypes: []
  });
  const [availableUsers, setAvailableUsers] = useState<UserProfile[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [userStatus, setUserStatus] = useState<'Open to Meeting People' | 'Not Looking to Meet People'>('Not Looking to Meet People');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showOptInAlert, setShowOptInAlert] = useState<boolean>(false);

  // Load user preferences on mount
  useEffect(() => {
    const loadPreferences = async () => {
      try {
        const response = await api.get('/meet/preferences');
        if (response.data && response.data.user_id) {
          // User has existing preferences
          const loadedClassYears = response.data.min_class_year && response.data.max_class_year
            ? Array.from({ length: response.data.max_class_year - response.data.min_class_year + 1 }, (_, i) => response.data.min_class_year + i)
            : [];

          setPreferences(prev => ({
            ...prev,
            classYears: loadedClassYears,
            residentialColleges: response.data.preferred_colleges || [],
            connectionTypes: response.data.looking_for || []
          }));
          setUserStatus(response.data.availability_status || 'Not Looking to Meet People');
        } else {
          // New user: set default preferences with all options checked
          setPreferences({
            availabilityType: 'Available Now',
            classYears: CLASS_YEARS.map(year => parseInt(year)),
            residentialColleges: Object.keys(RESIDENTIAL_COLLEGES),
            connectionTypes: CONNECTION_TYPES.map(({ id }) => id)
          });
          setUserStatus('Not Looking to Meet People'); // Default status for new users
        }
      } catch (error) {
        console.error('Failed to load preferences:', error);
        setError('Failed to load preferences. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    loadPreferences();
  }, []);

  // Fetch available users when preferences change or when userStatus changes
  const fetchAvailableUsers = useCallback(async () => {
    // Only fetch if user is opted in
    if (userStatus === 'Open to Meeting People') {
      try {
        const response = await api.get('/meet/available', {
          params: {
            ...preferences,
            classYears: preferences.classYears,
          }
        });
        setAvailableUsers(Array.isArray(response.data) ? response.data : []);
        setShowOptInAlert(false); // Hide alert after fetching users
      } catch (error) {
        console.error('Failed to fetch available users:', error);
        setError('Failed to fetch available users. Please try again later.');
        setAvailableUsers([]);
      }
    } else {
      // User is not opted in
      setAvailableUsers([]);
      setShowOptInAlert(true); // Show alert
    }
  }, [preferences, userStatus]);

  // Watch for changes in userStatus
  useEffect(() => {
    if (userStatus === 'Open to Meeting People') {
      setShowOptInAlert(false); // Hide alert when user opts in
      fetchAvailableUsers(); // Fetch users immediately
    } else {
      setShowOptInAlert(true); // Show alert when user opts out
    }
  }, [userStatus, fetchAvailableUsers]);

  const handlePreferenceChange = (type: keyof MeetPreferences, value: any) => {
    setPreferences(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const updateAvailability = useCallback(async (status: typeof userStatus) => {
    try {
      // If user is opting in, use default preferences
      const updatedPreferences = status === 'Open to Meeting People'
        ? {
            availabilityType: preferences.availabilityType,
            minClassYear: CLASS_YEARS.map(y => parseInt(y))[0],
            maxClassYear: CLASS_YEARS.map(y => parseInt(y))[CLASS_YEARS.length - 1],
            preferredColleges: Object.keys(RESIDENTIAL_COLLEGES),
            lookingFor: CONNECTION_TYPES.map(({ id }) => id)
          }
        : {
            availabilityType: preferences.availabilityType
          };
  
      await api.post('/meet/availability', {
        status,
        ...updatedPreferences
      });
  
      if (status === 'Open to Meeting People') {
        // If opting in, save default preferences
        await api.post('/meet/preferences', {
          minClassYear: updatedPreferences.minClassYear,
          maxClassYear: updatedPreferences.maxClassYear,
          preferredColleges: updatedPreferences.preferredColleges,
          lookingFor: updatedPreferences.lookingFor
        });
      }
  
      setUserStatus(status);
      // Do not call fetchAvailableUsers here
    } catch (error) {
      console.error('Failed to update availability:', error);
      setError('Failed to update availability. Please try again later.');
    }
  }, [preferences.availabilityType]);

  const savePreferences = async () => {
    if (preferences.connectionTypes.length === 0) {
      setError('Please select at least one connection type.');
      return;
    }
  
    try {
      await api.post('/meet/preferences', {
        minClassYear: preferences.classYears.length > 0 ? Math.min(...preferences.classYears) : null,
        maxClassYear: preferences.classYears.length > 0 ? Math.max(...preferences.classYears) : null,
        preferredColleges: preferences.residentialColleges,
        lookingFor: preferences.connectionTypes
      });
      setError('Preferences saved successfully!');
      setTimeout(() => setError(null), 3000);
    } catch (error) {
      console.error('Failed to save preferences:', error);
      setError('Failed to save preferences. Please try again later.');
    }
  };

  const handleCollegeClick = (college: string) => {
    const users = availableUsers.filter(user => user.residential_college === college);
    if (users.length > 0) {
      setSelectedUser(users[0]); // For now, just show the first user
    }
  };

  if (loading) {
    return (
      <div className="tiger-meet-container">
        <div className="loading-overlay">Loading...</div>
      </div>
    );
  }

  return (
    <div className="tiger-meet-container">
      <div className="preferences-sidebar">
        <h2 className="pixel-text-medium">TigerMeet</h2>
        
        {error && (
          <div className={`error-message ${error.includes('success') ? 'success' : ''}`}>
            {error}
            <button className="close-error" onClick={() => setError(null)}>×</button>
          </div>
        )}

        <section className="preference-section">
          <h3 className="pixel-text-small">Your Status</h3>
          <div className="status-toggle">
            <button
              className={`pixel-button ${userStatus === 'Open to Meeting People' ? 'active' : ''}`}
              onClick={() => updateAvailability('Open to Meeting People')}
            >
              Open to Meeting People
            </button>
            <button
              className={`pixel-button ${userStatus === 'Not Looking to Meet People' ? 'active' : ''}`}
              onClick={() => updateAvailability('Not Looking to Meet People')}
            >
              Not Looking to Meet People
            </button>
          </div>
        </section>

        {userStatus === 'Open to Meeting People' && (
          <>
            <section className="preference-section">
              <h3 className="pixel-text-small">Your Availability</h3>
              <select
                className="pixel-select"
                value={preferences.availabilityType}
                onChange={(e) => handlePreferenceChange('availabilityType', e.target.value)}
              >
                {AVAILABILITY_TYPES.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </section>

            <section className="preference-section">
              <h3 className="pixel-text-small">Looking For</h3>
              <div className="checkbox-group">
                {CONNECTION_TYPES.map(({ id, label }) => (
                  <label key={id} className="pixel-checkbox-label">
                    <input
                      type="checkbox"
                      checked={preferences.connectionTypes.includes(id)}
                      onChange={(e) => {
                        const newTypes = e.target.checked
                          ? [...preferences.connectionTypes, id]
                          : preferences.connectionTypes.filter(t => t !== id);
                        handlePreferenceChange('connectionTypes', newTypes);
                      }}
                    />
                    {label}
                  </label>
                ))}
              </div>
            </section>

            <section className="preference-section">
              <h3 className="pixel-text-small">Class Years</h3>
              <div className="checkbox-group">
                {CLASS_YEARS.map(year => (
                  <label key={year} className="pixel-checkbox-label">
                    <input
                      type="checkbox"
                      checked={preferences.classYears.includes(parseInt(year))}
                      onChange={(e) => {
                        const yearNum = parseInt(year);
                        const newYears = e.target.checked
                          ? [...preferences.classYears, yearNum].sort((a, b) => a - b)
                          : preferences.classYears.filter(y => y !== yearNum);
                        handlePreferenceChange('classYears', newYears);
                      }}
                    />
                    {year}
                  </label>
                ))}
              </div>
            </section>

            <section className="preference-section">
              <h3 className="pixel-text-small">Residential Colleges</h3>
              <div className="checkbox-group">
                {Object.keys(RESIDENTIAL_COLLEGES).map(college => (
                  <label key={college} className="pixel-checkbox-label">
                    <input
                      type="checkbox"
                      checked={preferences.residentialColleges.includes(college)}
                      onChange={(e) => {
                        const newColleges = e.target.checked
                          ? [...preferences.residentialColleges, college]
                          : preferences.residentialColleges.filter(c => c !== college);
                        handlePreferenceChange('residentialColleges', newColleges);
                      }}
                    />
                    {college}
                  </label>
                ))}
              </div>
            </section>

            <button 
              className="pixel-button update-button"
              onClick={savePreferences}
            >
              Save Preferences
            </button>
          </>
        )}
      </div>

      <div className="map-placeholder">
        {showOptInAlert && (
          <div className="opt-in-alert">
            <p>You must opt in to TigerMeet to view and match with other users.</p>
            <button 
              className="pixel-button"
              onClick={() => updateAvailability('Open to Meeting People')}
            >
              Opt In
            </button>
          </div>
        )}

        {!showOptInAlert && (
        <div className="colleges-grid">
          {Object.keys(RESIDENTIAL_COLLEGES).map(college => {
            const usersInCollege = availableUsers.filter(user => user.residential_college === college);
            return (
              <div
                key={college}
                className={`college-card ${usersInCollege.length > 0 ? 'has-users' : ''}`}
                onClick={() => handleCollegeClick(college)}
              >
                <h3>{college}</h3>
                <p>{usersInCollege.length} users available</p>
              </div>
            );
          })}
        </div>
        )}
      </div>

      {selectedUser && (
        <div className="user-profile-modal" onClick={() => setSelectedUser(null)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setSelectedUser(null)}>×</button>
            
            <div className="user-profile-header">
              <img 
                src={getImageUrl(selectedUser.photo_url)}
                alt={selectedUser.name}
                className="profile-picture"
              />
              <div className="user-info">
                <h3 className="pixel-text-large">{selectedUser.name}</h3>
                <p className="user-details">
                  Class of {selectedUser.class_year} • {selectedUser.residential_college}
                </p>
                <p className={`availability-tag status-${selectedUser.availability_type.replace(/\s+/g, '-').toLowerCase()}`}>
                  {selectedUser.availability_type}
                </p>
              </div>
            </div>

            <div className="user-profile-body">
              <div className="identity-section">
                {/* Gender and Orientation with colored text */}
                <p>
                  <span className={`identity-tag gender-${selectedUser.gender_identity.toLowerCase()}`}>
                    {selectedUser.gender_identity}
                  </span>
                </p>
                <p>
                  <span className={`identity-tag orientation-${selectedUser.sexual_orientation.toLowerCase().replace(/\s+/g, '-')}`}>
                    {selectedUser.sexual_orientation}
                  </span>
                </p>
              </div>

              <div className="bio-section">
                <h4 className="pixel-text-medium profile-section-header">About Me</h4>
                <p className="profile-text">{selectedUser.bio || 'No bio available'}</p>
              </div>

              {/* Additional Info Section */}
              {selectedUser.hobbies && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Hobbies</h4>
                  <p className="profile-text">{selectedUser.hobbies}</p>
                </div>
              )}

              {selectedUser.personality_type && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Personality Type</h4>
                  <p className="profile-text">{selectedUser.personality_type}</p>
                </div>
              )}

              {selectedUser.favorite_show && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Favorite Show</h4>
                  <p className="profile-text">{selectedUser.favorite_show}</p>
                </div>
              )}

              <div className="looking-for-section">
                <h4 className="pixel-text-medium profile-section-header">Looking For</h4>
                <div className="tags">
                  {selectedUser.looking_for.map(type => {
                    const connection = CONNECTION_TYPES.find(t => t.id === type);
                    return (
                      <span key={type} className={`tag looking-for-${type}`}>
                        {connection ? connection.label : type}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="user-profile-actions">
              {selectedUser.is_matched ? (
                <button className="pixel-button matched-button">
                  Already Matched!
                </button>
              ) : (
                <button className="pixel-button primary-button">
                  Send Match Request
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TigerMeetPage;