import axios from 'axios';

// Determine the API base URL based on the environment
const getBaseUrl = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  if (isDevelopment) {
    // Always use localhost:3001 in development
    return 'http://localhost:3001';
  }
  
  // In production, use the same origin
  return '';
};

export const api = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true, // Important for cookies
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add response interceptor to handle 401 responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // If we get a 401, we might need to refresh the page or redirect to login
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);
