// /client/src/utils/imageUtils.ts

// Get the base URL based on the current environment
const getBaseUrl = () => {
  const hostname = window.location.hostname;
  const protocol = window.location.protocol;
  
  // Use port 3001 only in development
  if (hostname === 'localhost' || hostname === 'localhost') {
    return `http://${hostname}:3001`;
  }
  
  // In production, use the same protocol and hostname
  return `${protocol}//${hostname}`;
};

// Utility function to ensure image URLs are properly formatted with server base URL
export const getImageUrl = (url: string | null | undefined): string => {
  if (!url) return '';

  // If the URL already starts with http/https, return it as is
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  // If it's a relative URL (starts with /), prepend the server base URL
  return `${getBaseUrl()}${url}`;
};
