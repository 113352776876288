import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutConfirmationModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    onClose(); // Close the modal
    navigate('/'); // Redirect to the landing page
  };

  return (
    <div className="pixel-modal-overlay">
      <div className="pixel-modal">
        <div className="pixel-modal__content">
          <p>You have been successfully logged out.</p>
        </div>
        <div className="pixel-modal__actions">
          <button className="pixel-button pixel-button--small" onClick={handleConfirm}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutConfirmationModal;