import React, { useEffect, useState } from 'react';
import { api } from '../utils/api';
import { getImageUrl } from '../utils/imageUtils';
import '../styles/MatchesPage.css';

interface Match {
  id: number;
  name: string;
  netid: string;
  major: string;
  class_year: number;
  bio: string;
  photo_url: string;
  anonymous_mode: boolean;
  score: number;
  hobbies?: string;
  personality_type?: string;
  favorite_show?: string;
  gender_identity?: string;
  sexual_orientation?: string;
}

interface PixelAlertProps {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  type?: 'info' | 'warning' | 'error';
}

const PixelAlert: React.FC<PixelAlertProps> = ({
  message,
  isOpen,
  onConfirm,
  onCancel,
  confirmText = 'OK',
  cancelText = 'CANCEL',
  type = 'info'
}) => {
  if (!isOpen) return null;

  return (
    <div className="pixel-alert-overlay" onClick={e => e.stopPropagation()}>
      <div className="pixel-alert">
        <div className={`pixel-alert-content ${type}`}>
          <div className="pixel-alert-message">{message}</div>
          <div className="pixel-alert-buttons">
            <button 
              className="pixel-button cancel-button" 
              onClick={onCancel}
            >
              {cancelText}
            </button>
            <button 
              className="pixel-button confirm-button" 
              onClick={onConfirm}
              autoFocus
            >
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MatchCard: React.FC<{
  match: Match;
  onClick: () => void;
  isSelected?: boolean;
}> = ({ match, onClick, isSelected }) => (
  <div 
    className={`match-card ${isSelected ? 'selected' : ''}`}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyPress={(e) => e.key === 'Enter' && onClick()}
  >
    <div className="match-image-container">
      {match.photo_url ? (
        <img 
          src={getImageUrl(match.photo_url)} 
          alt="Profile" 
          className="match-image"
        />
      ) : (
        <div className="match-image-placeholder">NO IMG</div>
      )}
    </div>
    <div className="match-info">
      <div className="match-name pixel-text-medium">
        {match.anonymous_mode ? 'Anonymous User' : match.name || match.netid}
      </div>
      <div className="match-details">
        <p>Major: {match.major || 'N/A'}</p>
        <p>Class Year: {match.class_year || 'N/A'}</p>
        <p>Match Score: {match.score.toFixed(2)}%</p>
      </div>
    </div>
  </div>
);

const MatchProfileModal: React.FC<{
  match: Match;
  onClose: () => void;
  onBlock: (id: number) => void;
}> = ({ match, onClose, onBlock }) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [alert, setAlert] = useState<{
    isOpen: boolean;
    message: string;
    type: 'info' | 'warning' | 'error';
  }>({
    isOpen: false,
    message: '',
    type: 'info'
  });

  const handleBlock = async () => {
    setIsBlocking(true);
    try {
      await api.post('/block', { blocked_id: match.id });
      onBlock(match.id);
      onClose();
    } catch (error) {
      setAlert({
        isOpen: true,
        message: 'Failed to block user. Please try again.',
        type: 'error'
      });
    }
    setIsBlocking(false);
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>×</button>
          
          <div className="user-profile-header">
            <img 
              src={getImageUrl(match.photo_url)}
              alt={match.name}
              className="profile-picture"
            />
            <div className="user-info">
              <h3 className="pixel-text-large">{match.name}</h3>
              <p className="user-details">
                {match.major && match.class_year ? `Class of ${match.class_year} • ${match.major}` : (match.class_year ? `Class of ${match.class_year}` : (match.major ? match.major : ''))}
              </p>
              <p className="match-score">Match Score: {match.score.toFixed(2)}%</p>
            </div>
          </div>

          <div className="user-profile-body">
              <div className="identity-section">
                {/* Gender and Orientation with colored text */}
                {match.gender_identity && <p>
                  <span className={`identity-tag gender-${match.gender_identity.toLowerCase()}`}>
                    {match.gender_identity}
                  </span>
                </p>}
                {match.sexual_orientation && <p>
                  <span className={`identity-tag orientation-${match.sexual_orientation.toLowerCase().replace(/\s+/g, '-')}`}>
                    {match.sexual_orientation}
                  </span>
                </p>}
              </div>

              <div className="bio-section">
                <h4 className="pixel-text-medium profile-section-header">About Me</h4>
                <p className="profile-text">{match.bio || 'No bio available'}</p>
              </div>

              {/* Additional Info Section */}
              {match.hobbies && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Hobbies</h4>
                  <p className="profile-text">{match.hobbies}</p>
                </div>
              )}

              {match.personality_type && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Personality Type</h4>
                  <p className="profile-text">{match.personality_type}</p>
                </div>
              )}

              {match.favorite_show && (
                <div className="additional-info-section">
                  <h4 className="pixel-text-medium profile-section-header">Favorite Show</h4>
                  <p className="profile-text">{match.favorite_show}</p>
                </div>
              )}
            </div>

          <div className="modal-actions">
            <button 
              className="pixel-button message-button"
              onClick={() => setAlert({
                isOpen: true,
                message: 'Messaging will be available in a future update!',
                type: 'info'
              })}
            >
              MESSAGE
            </button>
            <button 
              className="pixel-button block-button"
              onClick={() => setAlert({
                isOpen: true,
                message: 'Are you sure you want to block this user?',
                type: 'warning'
              })}
              disabled={isBlocking}
            >
              {isBlocking ? 'BLOCKING...' : 'BLOCK'}
            </button>
          </div>
        </div>
      </div>

      <PixelAlert
        isOpen={alert.isOpen}
        message={alert.message}
        type={alert.type}
        onConfirm={() => {
          if (alert.type === 'warning') {
            handleBlock();
          }
          setAlert(prev => ({ ...prev, isOpen: false }));
        }}
        onCancel={() => setAlert(prev => ({ ...prev, isOpen: false }))}
        confirmText={alert.type === 'warning' ? 'BLOCK' : 'OK'}
        cancelText="CANCEL"
      />
    </>
  );
};

const MatchesPage: React.FC = () => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const res = await api.get('/match/recommendations');
        setMatches(res.data);
      } catch (error) {
        setError('Failed to load matches. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchMatches();
  }, []);

  const handleBlock = (blockedId: number) => {
    setMatches(matches.filter(m => m.id !== blockedId));
  };

  if (loading) {
    return (
      <div className="matches-container">
        <div className="loading-screen">
          <div className="loading-text">Loading matches...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="matches-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="matches-container">
      <h1 className="matches-title">YOUR MATCHES</h1>
      
      {matches.length === 0 ? (
        <div className="no-matches-message">
          No matches available yet. Complete your profile and questionnaire to find matches!
        </div>
      ) : (
        <div className="matches-grid">
          {matches.map(match => (
            <MatchCard
              key={match.id}
              match={match}
              onClick={() => setSelectedMatch(match)}
              isSelected={selectedMatch?.id === match.id}
            />
          ))}
        </div>
      )}

      {selectedMatch && (
        <MatchProfileModal
          match={selectedMatch}
          onClose={() => setSelectedMatch(null)}
          onBlock={handleBlock}
        />
      )}
    </div>
  );
};

export default MatchesPage;