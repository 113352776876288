import React from 'react';
import { api } from '../utils/api';

interface LoginPageProps {
  setAlert:(msg:string|null)=>void;
}

const LoginPage: React.FC<LoginPageProps> = ({ setAlert }) => {
  const handleCASLogin = () => {
    // Use the configured API base URL for the login endpoint
    window.location.href = `${api.defaults.baseURL}/auth/login`;
  };

  return (
    <div className="card pixel-border auth-container">
      <h2 className="pixel-text-medium">Login</h2>
      <p className="pixel-text-small">
        Click the button below to log in with Princeton CAS.
      </p>
      <button 
        type="button" 
        className="button pixel-button" 
        onClick={handleCASLogin}
      >
        Login with CAS
      </button>
    </div>
  );
};

export default LoginPage;
