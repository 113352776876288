// /client/src/components/ProfileSetupPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/useAuth';
import { updateProfile, uploadPhoto } from '../store/userSlice';
import { getImageUrl } from '../utils/imageUtils';
import '../styles/ProfileSetupPage.css';

interface ProfileSetupPageProps {
  setAlert: (msg: string | null) => void;
}

const RESIDENTIAL_COLLEGES = [
  'Butler', 'Forbes', 'Mathey', 'NCW', 'Rocky', 'Whitman', 'Yeh'
];

const CLASS_YEARS = ['2025', '2026', '2027', '2028'];

const GENDER_IDENTITIES = ['Male', 'Female', 'Non-binary', 'Other'];

const SEXUAL_ORIENTATIONS = [
  'Asexual', 'Bisexual', 'Gay', 'Heterosexual', 'Lesbian', 'Pansexual', 'Other'
];

const ProfileSetupPage: React.FC<ProfileSetupPageProps> = ({ setAlert }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [photoHover, setPhotoHover] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [profile, setProfile] = useState({
    name: user.name || '',
    class_year: user.class_year || '',
    major: user.major || '',
    bio: user.bio || '',
    photo_url: user.photo_url || '',
    anonymous_mode: user.anonymous_mode === undefined ? false : user.anonymous_mode,
    hobbies: user.hobbies || '',
    personality_type: user.personality_type || '',
    favorite_show: user.favorite_show || '',
    residential_college: user.residential_college || '',
    gender_identity: user.gender_identity || '',
    sexual_orientation: user.sexual_orientation || ''
  });

  useEffect(() => {
    setProfile({
      name: user.name || '',
      class_year: user.class_year || '',
      major: user.major || '',
      bio: user.bio || '',
      photo_url: user.photo_url || '',
      anonymous_mode: user.anonymous_mode === undefined ? false : user.anonymous_mode,
      hobbies: user.hobbies || '',
      personality_type: user.personality_type || '',
      favorite_show: user.favorite_show || '',
      residential_college: user.residential_college || '',
      gender_identity: user.gender_identity || '',
      sexual_orientation: user.sexual_orientation || ''
    });
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
    
    setProfile(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      await dispatch(updateProfile(profile)).unwrap();
      setAlert('Profile updated successfully');
    } catch (err) {
      setAlert('Failed to update profile');
    }
    setSaving(false);
  };

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const result = await dispatch(uploadPhoto(e.target.files[0])).unwrap();
        setProfile(prev => ({
          ...prev,
          photo_url: result.photo_url
        }));
        setAlert('Photo uploaded successfully');
      } catch (err) {
        setAlert('Failed to upload photo');
      }
    }
  };

  if (user.loading) {
    return <div className="profile-loading">Loading profile...</div>;
  }

  return (
    <div className="profile-container">
      <h2 className="profile-title pixel-text-medium">Your Profile</h2>
      
      <form onSubmit={handleSubmit} className="profile-form">
        <div className="profile-grid">
          <div className="profile-left">
            <div 
              className="profile-photo-container"
              onMouseEnter={() => setPhotoHover(true)}
              onMouseLeave={() => setPhotoHover(false)}
              onClick={() => fileInputRef.current?.click()}
            >
              {profile.photo_url ? (
                <img 
                  src={getImageUrl(profile.photo_url)} 
                  alt="Profile" 
                  className="profile-photo"
                />
              ) : (
                <div className="profile-photo-placeholder">
                  <span>No Photo</span>
                </div>
              )}
              <div className={`profile-photo-overlay ${photoHover ? 'visible' : ''}`}>
                <span className="edit-icon">✎</span>
                <span>Change Photo</span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handlePhotoChange}
                accept="image/*"
                className="hidden"
              />
            </div>

            <div className="profile-basic-info">
              <div className="form-group">
                <label className="pixel-label">Name:</label>
                <input
                  name="name"
                  type="text"
                  value={profile.name}
                  onChange={handleChange}
                  className="pixel-input"
                  placeholder="Enter your name"
                />
              </div>

              <div className="form-group">
                <label className="pixel-label">Class Year:</label>
                <select
                  name="class_year"
                  value={profile.class_year}
                  onChange={handleChange}
                  className="pixel-select"
                >
                  <option value="">Select Class Year</option>
                  {CLASS_YEARS.map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label className="pixel-label">Major:</label>
                <input
                  name="major"
                  type="text"
                  value={profile.major}
                  onChange={handleChange}
                  className="pixel-input"
                  placeholder="Enter your major"
                />
              </div>
            </div>

            <div className="form-group bio-group">
              <label className="pixel-label">Bio:</label>
              <textarea
                name="bio"
                value={profile.bio}
                onChange={handleChange}
                className="pixel-textarea"
                placeholder="Tell us about yourself..."
              />
            </div>

            <div className="profile-preferences">
              <label className="preference-toggle">
                <input
                  type="checkbox"
                  name="anonymous_mode"
                  checked={profile.anonymous_mode}
                  onChange={handleChange}
                />
                <span className="toggle-label">Anonymous Mode</span>
              </label>
            </div>
          </div>

          <div className="profile-right">
            <h3 className="section-title pixel-text-small">Identity & Preferences</h3>
            
            <div className="form-group">
              <label className="pixel-label">Residential College:</label>
              <select
                name="residential_college"
                value={profile.residential_college}
                onChange={handleChange}
                className="pixel-select"
              >
                <option value="">Select Residential College</option>
                {RESIDENTIAL_COLLEGES.map(college => (
                  <option key={college} value={college}>{college}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="pixel-label">Gender Identity:</label>
              <select
                name="gender_identity"
                value={profile.gender_identity}
                onChange={handleChange}
                className="pixel-select"
              >
                <option value="">Select Gender Identity</option>
                {GENDER_IDENTITIES.map(gender => (
                  <option key={gender} value={gender}>{gender}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="pixel-label">Sexual Orientation:</label>
              <select
                name="sexual_orientation"
                value={profile.sexual_orientation}
                onChange={handleChange}
                className="pixel-select"
              >
                <option value="">Select Sexual Orientation</option>
                {SEXUAL_ORIENTATIONS.map(orientation => (
                  <option key={orientation} value={orientation}>{orientation}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="pixel-label">Hobbies:</label>
              <input
                name="hobbies"
                type="text"
                value={profile.hobbies}
                onChange={handleChange}
                className="pixel-input"
                placeholder="Enter hobbies (comma-separated)"
              />
            </div>

            <div className="form-group">
              <label className="pixel-label">Personality Type:</label>
              <input
                name="personality_type"
                type="text"
                value={profile.personality_type}
                onChange={handleChange}
                className="pixel-input"
                placeholder="e.g., INFJ"
                maxLength={4}
              />
            </div>

            <div className="form-group">
              <label className="pixel-label">Favorite Show:</label>
              <input
                name="favorite_show"
                type="text"
                value={profile.favorite_show}
                onChange={handleChange}
                className="pixel-input"
                placeholder="Enter your favorite show"
              />
            </div>

            <button 
              type="submit" 
              className={`save-button pixel-button ${saving ? 'saving' : ''}`}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save Profile'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileSetupPage;