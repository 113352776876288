import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../utils/api';

interface UserState {
  id: number|null;
  netid: string|null;
  name: string|null;
  class_year: string|null;
  major: string|null;
  bio: string|null;
  romance_pref: boolean;
  platonic_pref: boolean;
  photo_url: string|null;
  anonymous_mode: boolean;
  hobbies: string|null;
  personality_type: string|null;
  favorite_show: string|null;
  residential_college: string|null;
  gender_identity: string|null;
  sexual_orientation: string|null;
  loading: boolean;
}

const initialState: UserState = {
  id: null,
  netid: null,
  name: null,
  class_year: null,
  major: null,
  bio: null,
  romance_pref: true,
  platonic_pref: true,
  photo_url: null,
  anonymous_mode: false,
  hobbies: null,
  personality_type: null,
  favorite_show: null,
  residential_college: null,
  gender_identity: null,
  sexual_orientation: null,
  loading: true
};

export const fetchCurrentUser = createAsyncThunk('user/fetchCurrent', async () => {
  const res = await api.get('/auth/current');
  return res.data;
});

export const updateProfile = createAsyncThunk('user/updateProfile', async (profileData: Partial<UserState>) => {
  const res = await api.post('/user/profile', profileData);
  return res.data;
});

export const uploadPhoto = createAsyncThunk('user/uploadPhoto', async (photo: File) => {
  const formData = new FormData();
  formData.append('photo', photo);
  const res = await api.post('/user/upload-photo', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return res.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      return initialState;
    },
    setPhoto(state, action) {
      state.photo_url = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            ...action.payload,
            loading: false
          };
        }
        return initialState;
      })
      .addCase(fetchCurrentUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            ...action.payload
          };
        }
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        if (action.payload?.photo_url) {
          state.photo_url = action.payload.photo_url;
        }
      });
  }
});

export const { logout, setPhoto } = userSlice.actions;
export default userSlice.reducer;
