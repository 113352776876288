// /client/src/components/NavigationBar.tsx

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavigationBarProps {
  loggedIn: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ loggedIn }) => {
  const location = useLocation();

  return (
    <div className="navbar pixel-border-inverse">
      <Link to="/" className={`nav-link pixel-button-small ${location.pathname === '/' ? 'active' : ''}`}>
        Home
      </Link>
      {loggedIn ? (
        <>
          <Link to="/questionnaire" className={`nav-link pixel-button-small ${location.pathname === '/questionnaire' ? 'active' : ''}`}>
            Questionnaire
          </Link>
          <Link to="/profile" className={`nav-link pixel-button-small ${location.pathname === '/profile' ? 'active' : ''}`}>
            Profile
          </Link>
          <Link to="/app" className={`nav-link pixel-button-small ${location.pathname === '/app' ? 'active' : ''}`}>
            Matches
          </Link>
          <Link to="/meet" className={`nav-link pixel-button-small ${location.pathname === '/meet' ? 'active' : ''}`}>
            TigerMeet
          </Link>
        </>
      ) : (
        <Link to="/login" className={`nav-link pixel-button-small ${location.pathname === '/login' ? 'active' : ''}`}>
          Login
        </Link>
      )}
    </div>
  );
};

export default NavigationBar;