import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAuth';

interface TypewriterTextProps {
  text: string;
  speed?: number;
  onComplete?: () => void;
}

interface PixelButtonProps {
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  className?: string;
}

interface FeatureCardProps {
  title: string;
  description: string;
  icon: string;
}

interface LandingPageProps {
  setAlert: (message: string | null) => void;
}

const TypewriterText: React.FC<TypewriterTextProps> = ({ 
  text, 
  speed = 50, 
  onComplete 
}) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, speed);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, text, speed, onComplete]);

  return (
    <span className="pixel-text-large whitespace-pre-wrap">{displayText}</span>
  );
};

const PixelButton: React.FC<PixelButtonProps> = ({ 
  children, 
  to, 
  onClick, 
  variant = 'primary', 
  className = '' 
}) => {
  const baseClasses = 'inline-block px-6 py-3 text-center transition-all duration-200 border-2 border-black shadow-pixel hover:translate-y-[-2px] active:translate-y-0 cursor-pointer';
  const variants: Record<'primary' | 'secondary', string> = {
    primary: 'bg-[#FAE7EC] hover:bg-[#FF9DAE]',
    secondary: 'bg-[#BFB1E8] hover:bg-[#9F8EC7]'
  };

  const classes = `${baseClasses} ${variants[variant]} ${className}`;

  if (to) {
    return <Link to={to} className={classes}>{children}</Link>;
  }

  return (
    <button onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon }) => (
  <div className="bg-[#FAE7EC] p-4 border-2 border-black shadow-pixel hover:translate-y-[-2px] transition-transform duration-200">
    <div className="mb-2 text-2xl">{icon}</div>
    <h3 className="text-sm mb-2 font-pixel uppercase">{title}</h3>
    <p className="text-xs font-pixel">{description}</p>
  </div>
);

const LandingPage: React.FC<LandingPageProps> = ({ setAlert }) => {
  const { id, name } = useAppSelector(state => state.user);
  const [showContent, setShowContent] = useState(false);

  const welcomeText = id 
    ? `HELLO, ${name || 'USER'}!`
    : 'WELCOME TO TIGERMATCH!';

  const features: FeatureCardProps[] = [
    {
      icon: '💝',
      title: 'Find Connections',
      description: 'Connect with fellow students who share your interests and values'
    },
    {
      icon: '🎮',
      title: 'Retro Style',
      description: 'Experience nostalgia with our unique pixel art interface'
    },
    {
      icon: '🔒',
      title: 'Privacy First',
      description: 'Control your visibility with customizable privacy settings'
    }
  ];

  if (!id) {
    return (
      <div className="min-h-[calc(100%-60px)] p-6">
        <div className="max-w-2xl mx-auto bg-[#FAE7EC] border-2 border-black p-8 shadow-pixel">
          <div className="h-16 mb-8">
            <TypewriterText 
              text={welcomeText}
              onComplete={() => setShowContent(true)}
            />
          </div>
          
          <div className={`transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
            <p className="font-pixel text-sm mb-8 leading-relaxed">
              Ready to connect with fellow Princeton students? Join our retro-themed
              community where meaningful connections await!
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>

            <div className="text-center">
              <PixelButton 
                to="/login" 
                variant="primary" 
                className="text-sm"
              >
                LOG IN TO START
              </PixelButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100%-60px)] p-6">
      <div className="max-w-2xl mx-auto bg-[#FAE7EC] border-2 border-black p-8 shadow-pixel">
        <div className="h-16 mb-8">
          <TypewriterText 
            text={welcomeText}
            onComplete={() => setShowContent(true)}
          />
        </div>

        <div className={`transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
          <p className="font-pixel text-sm mb-8">
            Welcome back! Here's what you can do:
          </p>

          <div className="space-y-4">
            <PixelButton 
              to="/questionnaire" 
              variant="primary"
              className="w-full text-sm"
            >
              COMPLETE/UPDATE QUESTIONNAIRE
            </PixelButton>

            <PixelButton 
              to="/profile" 
              variant="secondary"
              className="w-full text-sm"
            >
              EDIT YOUR PROFILE
            </PixelButton>

            <PixelButton 
              to="/app" 
              variant="primary"
              className="w-full text-sm"
            >
              VIEW YOUR MATCHES
            </PixelButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;